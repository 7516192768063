import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import BlogPost from "../components/blog-post";

const RootIndex = ({ data }) => {
  const siteTitle = data?.site?.siteMetdata?.title;
  const posts = data?.allContentfulBlogPost?.edges;

  return (
    <Layout>
      <Helmet title={siteTitle} />
      <div className="relative max-w-7xl mx-auto">
        <div className="relative pt-8 sm:pt-16 pb-20 px-4 sm:px-6 lg:pt-16 lg:pb-28 lg:px-8">
          <Hero />

          <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
            {posts.map((p) => (
              <BlogPost data={p.node} key={p.node.title} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          shortBio
        }
      }
    }
  }
`;
