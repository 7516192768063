import * as React from "react";

const Hero = ({ data }) => (
  <div className="flex flex-col align-center items-center justify-start lg:flex-row  max-w-lg lg:max-w-none mx-auto">
    <div>
      <div className="text-lg leading-6 font-medium space-y-1">
        <h4 className="">Software blog</h4>
      </div>
      <div className="text-lg leading-7 text-gray-500 mb-2">
        Software engineers: What do they know? Do they know things?? Lets find
        out!
      </div>
    </div>
  </div>
);

export default Hero;
